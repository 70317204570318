.ourWorkAndroid{
    .heading{
        font-size: 28px;
        font-family: 'Poppins';
        font-weight: 400;
        margin-left: 20px;
        margin-bottom: 0px;

    }
    .Logos{
        height: 70px;

    }
    .heading2{
        font-size: 34px;
    color: #9DC9A7;
    font-family: 'Poppins';
    font-weight: 600;
    margin-left: 20px;
    margin-bottom: 0px;

    }
    .mainHeading{
        line-height: 1.2;
margin: 0px;
    }
    .vertical-center{
        margin-top: auto;
        margin-bottom: auto;
    }
    .Text{
        font-size: 18px;
        color: #393939;
        line-height: 1.2;
        font-family: 'Poppins';
        font-weight: 200;
        margin-top: 18px;
        opacity: 0.8;
    }
    .btnn{
        width: 50%;
        height: 58px;
        background: #9DC9A7;
        color: white;
        border: none;
        border-radius: 100px;
        font-size: 15px;
        margin-top: 22px;
        font-weight: 700;
    }
    .logoions{
        height: 50px;
        width: 50px;
        margin-left: 20px;
    }
}
.ourWorkWordpress{
    .Logo{
        height: 90px;

    }
}