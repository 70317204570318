.Section4Container{
    background: #9DC9A7;
    padding: 40px;

}
.ImgBtn{
    width: 70px;
    background: white;
    text-align: center;
    height: 70px;
    border-radius: 15px;
    box-shadow: 0px 0px 12px 3px #b3b3b3;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: auto;
    padding-top: 10px;
    position: relative;
    z-index: 2;
}


.ImgBtn:hover{
    background: black;
    cursor: pointer;
}
.ImgBtnImg{
    height: 50px;
    width: 52px;
    object-fit: cover;

}
.UIIMG {
    width: 80% ;
    position: absolute;
    bottom: 15%;
}
.Section4HEading3 {
    font-size: 35px;
    font-family: 'Poppins';
    font-weight: 600;
    line-height: 38px;
    margin-bottom: 0px;
    color: #fff;
}
.Section4HEading4 {
    font-size: 20px;
    font-family: 'Poppins';
    font-weight: 600;
    margin-bottom: 0px;
    font-weight: 400;
}
.Section4HEading5 {
    font-size: 19px;
    font-family: 'Poppins';
    font-weight: 600;
    margin-bottom: 0px;
    font-weight: 600;
    color: white;
}
.section4Imgs{
    margin-top: 15px;
    height: 50px;

}
.bottomDiv{
    position: absolute;
    bottom: 28px;
    margin-left: 33px;
}
.S4Bdr{
    width: 63px;
    border-top: 3px solid;
    margin: auto;
    border-radius: 8px;
    margin-left: 11px;
}
@media (max-width: 767px) {
    .UIIMG {
        position: relative;
        bottom: -10%;
        width: 100%;
    }
    .Section3LeftImg{
        display: none;
    }
    .Section4Container {
        background: #9DC9A7;
        padding: 30px 15px 30px 15px;
    }
    .bottomDiv {
        position: relative;
        bottom: -10px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .Section4HEading3 {
        font-size: 38px;
        text-align: center;

    }
        .ImgBtn {
            width: 100%;
            height: 76px;
            border-radius: 14px;
            padding-top: 13px;
            margin-top: 31px;
            margin-bottom: 0px;
        } 
        .ImgBtnImg {
            height: 45px;
            width: 45px;
        }     
        .Section4HEading5 {
            font-size: 18px;
            margin-bottom: 20px;
            margin-top: 15px;
        }  
}