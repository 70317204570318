.Section6Container{
    background: #F4BD76;
    padding: 40px 40px 130px 40px;

}
.S6-btn{
    height: 70px;
    width: 70px;
    background: white;
    border: none;
    border-radius: 15px;
    font-size: 40px;
    font-family: 'Poppins';
    font-weight: 700;
}
.S6-btn:hover{
    background-color: black;
    color: white;
}
.btnLabel{
    font-size: 20px;
    margin-left: 10px;
    font-family: 'Poppins';
    font-weight: 400;
    line-height: 1.1;
}
.s6book1{
    height: 250px;
    position: absolute;
    right: 7%;
}
.s6book2{
    height: 250px;
    position: absolute;
    right: 35%;
    top: 125px;
}

.s6book3{
    height: 250px;
    position: absolute;
    right: 63%;
    top: 250px;
}
.s6book4{
    height: 250px;
    position: absolute;
    right: 7%;
    top: 270px;
    z-index: 2;


}
.s6book5{
    height: 250px;
    position: absolute;
    right: 35%;
    top: 370px;


}

.s7img{
    height: 390px;

}
.s7img:hover{
    opacity: 0.9;
    cursor: pointer;
}
.s7txt1{
    position: absolute;
    top: 31px;
    color: white;
    left: 40px;
    font-size: 15px;
}
.s7txt2{
    position: absolute;
    top: 47px;
    color: white;
    left: 38px;
    font-size: 21px;

}
.Section7_HEading3 {
    font-size: 35px;
    font-family: 'Poppins';
    font-weight: 600;
    line-height: 44px;
    margin-bottom: 0px;
    color: #F4BD76;
}
@media (max-width: 767px) {
    .Section5_HEading3{
        font-size: 38px;
    }
    .Section6Container {
        background: #F4BD76;
        padding: 30px 0px 30px 0px;

        // padding: 10px 10px 130px 10px;
    }
    .s6book1{
        position: relative;
        right: 0px;
        height: 200px;
        margin-left: auto;
        margin-right: auto;
    
    }
    .s6book2{
        position: relative;
        right: 0px;
        height: 200px;
        margin-left: auto;
        margin-right: auto;
        top: 0px;
    
    }
    .s6book3{
        position: relative;
        right: 0px;
        height: 200px;
        margin-left: auto;
        margin-right: auto;
        top: 0px;
    
    }
    
    .s6book4{
        position: relative;
        right: 0px;
        height: 200px;
        margin-left: auto;
        margin-right: auto;
        top: 0px;
    
    }
    .s6book5{
        position: relative;
        right: 0px;
        height: 200px;
        margin-left: auto;
        margin-right: auto;
        top: 0px;

    
    }

}