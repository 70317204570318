.Section8Container {
    background: #E96F82;
    padding: 30px;
}
.section8_text_right{
    text-align: right;
}
.s8btn{
    height: 84px;
    width: 84px;
    background: black;
    border: none;
    border-radius: 14px;
    margin-right: 14px;

   
}
.s8btnText{
    font-size: 18px;
    color: white;
    font-family: 'Poppins';
    font-weight: 500;
    margin-top: 7px;

}
.s8w-65{
    width: 65%;
}
.s8IMG{
    height: 600px;
    object-fit: contain;
    position: relative;
    z-index: 2;
}
.s8Mt{
    margin-top: auto;
}
@media (max-width: 767px) {

.section8_text_right {
    text-align: center;
}
.s8IMG {
    height: 100%;
    margin-top: 40px;

}
.s8btn{
    margin: 0px;
}
.s8btnText {
    font-size: 14px;
}
}