.LGsScreen{
    width: 125%;
}
.LGSLOGO{
    height: 85px;

}
.LGsHeading{
    color: #F29700;
    font-size: 32px;
}
.lgsText{
    font-size: 16px;
    margin: 0px;
}
.rhconslt{
    height: 80px;
    margin-top: 20px;
}
.uiSection2{
    background: #F29700;
    color: white;
    border-radius: 15px;
    padding: 45px 60px 45px 60px;

}
.uiSection2Text{
    font-size: 24px;

}

.uiSection2btn{
    height: 70px;
    width: 70px;
    border: none;
    color: #F29700;
    font-size: 26px;
    font-weight: 700;
    font-family: 'Poppins';
    border-radius: 10px;
}
.uiSection2btn:hover{
    background: black;
    color: white;
}
.uiSection2Text1{
    font-size: 18px;
    line-height: 1.3;

}
.uisection3Text{
    color: #F29700;
    font-size: 24px;

}
.uisection3heading{
    color: #F29700;
    font-size: 15px;

}
.uisection3MainHEading{
    font-size: 30px;

}
.uisection3f20{
    font-size: 20px;
    line-height: 1.3;

}
.uisection3rhconslt{
    margin-top: auto;
    margin-bottom: auto;
}
.uisection3rhconsltlogo{
    height: 139px;

}
.UIUxsection4{
    background: #23934C;
    color: white;
    text-align: center;
    padding: 50px;
    margin-top: 50px;
    .f20{
        font-size: 20px;
    }
    .f35{
        font-size: 35px;
    }
    .f16{
        font-size: 16px;
    }
    .UIUxsection4platform{
        font-family: 'Poppins';
        font-weight: 400;
    
    }
    .UIUxsection4platform:hover{
        font-weight: 600;
        border-bottom: 1px solid #ffffff;
        cursor: pointer;

    }
    
}

.uiSection5branding{
    height: 420px;

}
.vertical_center{
    margin-top: auto;
    margin-bottom: auto;
}
.LGSLOGO1 {
    height: 128px;
}
.brandingheading{
    font-size: 30px;
    opacity: 0.34;
}
.uibackgorund{

width: 148%;
position: absolute;
left: -17%;
top: -19px;
height: 108%;

}
.LgsPrimaryClr{
    color: #F29700;
}
.Montserrat1{
    font-size: 15px;
    text-align: right;
    font-family: Montserrat;
    font-weight: 600;
}
.Montserrat2{
    font-size: 150px;
    text-align: right;
    font-family: Montserrat;
    font-weight: 900;
    line-height: 0.6;
    margin-bottom: 10px;

}
.Montserrat3{
    font-size: 15px;
    text-align: right;
    font-family: Montserrat;
    font-weight: 100;
    margin: 0px;

}
.Montserrat4{
    font-size: 15px;
    text-align: right;
    font-family: Montserrat;
    font-weight: 200;
    margin: 0px;

}
.Montserrat5{
    font-size: 15px;
    text-align: right;
    font-family: Montserrat;
    font-weight: 300;
    margin: 0px;

}.Montserrat6{
    font-size: 15px;
    text-align: right;
    font-family: Montserrat;
    font-weight: 400;
    margin: 0px;

}.Montserrat7{
    font-size: 15px;
    text-align: right;
    font-family: Montserrat;
    font-weight: 500;
    margin: 0px;

}
.Montserrat8{
    font-size: 15px;
    text-align: right;
    font-family: Montserrat;
    font-weight: 600;
    margin: 0px;

}
.Montserrat9{
    font-size: 15px;
    text-align: right;
    font-family: Montserrat;
    font-weight: 700;
    margin: 0px;

}
.Montserrat10{
    font-size: 15px;
    text-align: right;
    font-family: Montserrat;
    font-weight: 800;
    margin: 0px;

}
.Montserrat11{
    font-size: 15px;
    text-align: right;
    font-family: Montserrat;
    font-weight: 900;
    margin: 0px;
}
.colors{
    .orange{
        height: 100px;
        width: 100px;
        background: #F69522;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;
    }
    .green{
        height: 100px;
        width: 100px;
        background: #23934C;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;
    }
    .pink{
        height: 100px;
        width: 100px;
        background: #F16262;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;
    }
    .white{
        height: 100px;
        width: 100px;
        background: #ffffff;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;
    }
    .black{
        height: 100px;
        width: 100px;
        background: #2D2926;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;
    }
    .blue{
        height: 100px;
        width: 100px;
        background: #3F4185;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;
    }
}
.icondiv{
    width: 14.24%;
    text-align: center;

}
.icon{
    width: 50%;
}

.userinterface{
    .heading{
        text-align: center;
        font-size: 22px;
        color: #F29700;
    }
    .Mainheading{
        text-align: center;
        font-size: 45px;
    }
    .fullscreen2{
        width: 80%;
        position: absolute;
        right: 0px;
        top: 20%;
    }
    .fullscreen1{
        width: 74%;
        opacity: 0.8;
      
    }
    
}