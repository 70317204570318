.Section9_HEading3 {
  font-size: 35px;
  font-family: 'Poppins';
  font-weight: 600;
  line-height: 44px;
  margin-bottom: 0px;
  color: #E96F82;
}
.Section9Container{

    .container {
        position: relative;
        width: 100%;
        padding: 0px;
      }
      
      .s9img {
        display: block;
        width: 100%;
        height: auto;
        cursor: pointer;
      }
      .s9img1{
        height: 376px;
      }
      .overlay {
        position: absolute;
        bottom: 0;
        background: black;
        background: rgba(255, 255, 255, 0.7); /* Black see-through */
        color: #f1f1f1;
        width: 100%;
        transition: 1.5s ease;
        opacity: 0;
        color: black;
        height: 100%;
        padding-left: 15px;
        border-radius: 25px;
        padding-top: 15px;
      }
      
      .container:hover .overlay {
        opacity: 1;
      }
      .overlaytxt1{
          font-size: 12px;
      }
      .overlaytxt2{
          font-size: 18px;
      }
      .bottomImg{
        position: absolute;
        bottom: 8%;
        right: 9%;
      }
      .s9BottomImg{
        position: absolute;
        bottom: 15%;
        right: 11%;
    }
    .s9BottomImg1 {
      position: absolute;
      bottom: 5%;
      right: 15%;
  }
  .s9BottomImg2 {
    position: absolute;
    bottom: 4%;
    right: 18%;
}
      
}
.Section7Container{

  .container {
      position: relative;
      width: 100%;
    }
    
    .s9img {
      display: block;
      width: 100%;
      height: auto;
      cursor: pointer;
    }
    .s7btn{
      position: absolute;
      bottom: 0px;
      height: 50px;
      width: 100%;
      left: 0px;
      border-bottom-right-radius: 15px;
      border-bottom-left-radius: 15px;
      background: black;
      color: white;
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 13px;
    }
    .s7img1{
      height: 390px;
      width: 119% !important;    }
    .overlay {
      position: absolute;
      bottom: 0;
      background: white;
      background: #F4BD76 ;
      color: #f1f1f1;
      width: 100%;
      transition: 0.5s ease;
      opacity: 0;
      color: black;
      height: 100%;
      padding-left: 15px;
      border-radius: 25px;
      padding-top: 15px;
      cursor: pointer;
    }
    
    .container:hover .overlay {
      opacity: 1;
    }
    .overlaytxt1{
        font-size: 12px;
    }
    .overlaytxt2{
        font-size: 18px;

    }
    .overlaybullets{
      font-size: 14px;
      color: white;
      margin: 1px;

    }
    .overlayheading{
      font-size: 15px;

    }
    .bottomImg{
      position: absolute;
      bottom: 8%;
      right: 9%;
    }
    .s9BottomImg{
      position: absolute;
      bottom: 15%;
      right: 11%;
  }
  .s9BottomImg1 {
    position: absolute;
    bottom: 5%;
    right: 15%;
}
.s9BottomImg2 {
  position: absolute;
  bottom: 4%;
  right: 18%;
}
    
}

.Section2Container{

  .container {
      position: relative;
      width: 100%;
      padding: 0px;
    }
    
    .s9img {
      display: block;
      width: 100%;
      height: auto;
      cursor: pointer;
    }
    .s9img1{
      height: 344px;
    }
    .overlay {
      position: absolute;
      bottom: 0;
      background: black;
      background: rgba(255, 255, 255, 0); /* Black see-through */
      color: #f1f1f1;
      width: 100%;
      transition: 1.5s ease;
      opacity: 0;
      color: black;
      height: 100%;
      padding-left: 15px;
      border-radius: 25px;
      padding-top: 15px;
    }
    
    .container:hover .overlay {
      opacity: 1;
    }
    .overlaytxt1{
        font-size: 12px;
    }
    .overlaytxt2{
        font-size: 18px;
    }
    .bottomImg{
      position: absolute;
      bottom: 8%;
      right: 9%;
    }
    .s9BottomImg{
      position: absolute;
      bottom: 15%;
      right: 11%;
  }
  .s9BottomImg1 {
    position: absolute;
    bottom: 5%;
    right: 15%;
}
.s9BottomImg2 {
  position: absolute;
  bottom: 4%;
  right: 18%;
}
    
}

@media (max-width: 767px) {

.Section7Container .s7img1 {
  height: 390px;
  width: 100% !important;
}
.Section7Container .overlay {
 
  width: 86%;
}
}
@media (max-width: 767px) {

.Section9_HEading3 {
  font-size: 34px;
}
.Section9Container .s9img {
  height: 300px;
}
.Section9Container .overlaytxt2 {
  font-size: 15px;
}
}