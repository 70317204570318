.Section5_HEading3 {
    font-size: 35px;
    font-family: 'Poppins';
    font-weight: 600;
    line-height: 44px;
    margin-bottom: 0px;
    color: #9DC9A7;
}
.Section5Card{
    height: 190px;;
    box-shadow: 0px 0px 10px 0px #e0e0e0;    z-index: 2;
    position: relative;
    border-radius: 25px;
}

.section5-WordpressCard {
  
    height: 190px;;
    box-shadow: 0px 0px 10px 0px #e0e0e0;    z-index: 2;
    position: relative;
    border-radius: 25px;
    text-align: center;
    padding-top: 25px;
    cursor: pointer;
    background: white;

   


}
.section5-WordpressCard:hover > .wordpress-card-background{
    background-image: url('../../Images/Section5/wordpress.png');
    
}
.section5-WordpressCard:hover  {
    background-color: #000;
    color: white;
}

.wordpress-card-background {
    background-image: url('../../Images/Section5/wordpressblack.png');
    background-position: center;
   height: 85px;;
   width: 78px;
     background-repeat: no-repeat;
    visibility: visible;
    background-position: center;
    margin-left: auto;
    margin-right: auto;

}
.section5-Android-Card {
  
    height: 190px;;
    box-shadow: 0px 0px 10px 0px #e0e0e0;    z-index: 2;
    position: relative;
    border-radius: 25px;
    text-align: center;
    padding-top: 25px;
    cursor: pointer;
    background: white;


   


}
.section5-Android-Card:hover > .Android-card-background{
    background-image: url('../../Images/Section5/androidWhite.png');
    
}
.section5-Android-Card:hover  {
    background-color: #000;
    color: white;
}

.Android-card-background {
    background-image: url('../../Images/Section5/android.png');
    background-position: center;
    height: 85px;;
   width: 78px;
     background-repeat: no-repeat;
    visibility: visible;
    background-position: center;
    margin-left: auto;
    margin-right: auto;

}

.section5-ECommerce-Card {
  
    height: 190px;;
    box-shadow: 0px 0px 10px 0px #e0e0e0;    z-index: 2;
    position: relative;
    border-radius: 25px;
    text-align: center;
    padding-top: 25px;
    cursor: pointer;
}
.section5-ECommerce-Card:hover > .ECommerce-card-background{
    background-image: url('../../Images/Section5/cartWhite.png');
    
}
.section5-ECommerce-Card:hover  {
    background-color: #000;
    color: white;
}

.ECommerce-card-background {
    background-image: url('../../Images/Section5/cart.png');
    background-position: center;
   height: 85px;;
   width: 78px;
     background-repeat: no-repeat;
    visibility: visible;
    background-position: center;
    margin-left: auto;
    margin-right: auto;

}


.section5-UI-Card {
  
    height: 190px;;
    box-shadow: 0px 0px 10px 0px #e0e0e0;    z-index: 2;
    position: relative;
    border-radius: 25px;
    text-align: center;
    padding-top: 25px;

    background: white;
}
.section5-UI-Card:hover > .UI-card-background{
    background-image: url('../../Images/Section5/ApplicationWhite.png');
    
}
.section5-UI-Card:hover  {
    background-color: #000;
    color: white;
}

.UI-card-background {
    background-image: url('../../Images/Section5/Application.png');
    background-position: center;
   height: 85px;;
   width: 78px;
     background-repeat: no-repeat;
    visibility: visible;
    background-position: center;
    margin-left: auto;
    margin-right: auto;

}

.section5-NAtive-Card {
  
    height: 190px;;
    box-shadow: 0px 0px 10px 0px #e0e0e0;    z-index: 2;
    position: relative;
    border-radius: 25px;
    text-align: center;
    padding-top: 25px;
    cursor: pointer;
}
.section5-NAtive-Card:hover > .NAtive-card-background{
    background-image: url('../../Images/Section5/reactWhite.png');
    
}
.section5-NAtive-Card:hover  {
    background-color: #000;
    color: white;
}

.NAtive-card-background {
    background-image: url('../../Images/Section5/react.png');
    background-position: center;
   height: 85px;;
   width: 120px;
     background-repeat: no-repeat;
    visibility: visible;
    background-position: center;
    margin-left: auto;
    margin-right: auto;

}

.section5-d3d-Card {
  
    height: 190px;;
    box-shadow: 0px 0px 10px 0px #e0e0e0;    z-index: 2;
    position: relative;
    border-radius: 25px;
    text-align: center;
    padding-top: 25px;
    cursor: pointer;
}
.section5-d3d-Card:hover > .d3d-card-background{
    background-image: url('../../Images/Section5/3d-designWhite.png');
    
}
.section5-d3d-Card:hover  {
    background-color: #000;
    color: white;
}

.d3d-card-background {
    background-image: url('../../Images/Section5/3d-design.png');
    background-position: center;
   height: 85px;;
   width: 120px;
     background-repeat: no-repeat;
    visibility: visible;
    background-position: center;
    margin-left: auto;
    margin-right: auto;

}

.NAtive-card-background {
    background-image: url('../../Images/Section5/react.png');
    background-position: center;
   height: 85px;;
   width: 120px;
     background-repeat: no-repeat;
    visibility: visible;
    background-position: center;
    margin-left: auto;
    margin-right: auto;

}

.section5-net-Card {
  
    height: 190px;;
    box-shadow: 0px 0px 10px 0px #e0e0e0;    z-index: 2;
    position: relative;
    border-radius: 25px;
    text-align: center;
    padding-top: 25px;
    cursor: pointer;
}
.section5-net-Card:hover > .net-card-background{
    background-image: url('../../Images/Section5/interfaceWhite.png');
    
}
.section5-net-Card:hover  {
    background-color: #000;
    color: white;
}

.net-card-background {
    background-image: url('../../Images/Section5/interface.png');
    background-position: center;
   height: 85px;;
   width: 120px;
     background-repeat: no-repeat;
    visibility: visible;
    background-position: center;
    margin-left: auto;
    margin-right: auto;

}

.section5-Web-Card {
  
    height: 190px;;
    box-shadow: 0px 0px 10px 0px #e0e0e0;    z-index: 2;
    position: relative;
    border-radius: 25px;
    text-align: center;
    padding-top: 25px;
    cursor: pointer;
}
.section5-Web-Card:hover > .Web-card-background{
    background-image: url('../../Images/Section5/computerWhite.png');
    
}
.section5-Web-Card:hover  {
    background-color: #000;
    color: white;
}

.Web-card-background {
    background-image: url('../../Images/Section5/computer\ \(2\).png');
    background-position: center;
   height: 85px;;
   width: 120px;
     background-repeat: no-repeat;
    visibility: visible;
    background-position: center;
    margin-left: auto;
    margin-right: auto;

}



.Section5CardText{
    font-size: 15px;
    margin-top: 30px;

}
@media (max-width: 767px) {
    .Section5_HEading3{
        font-size: 38px;
    }
    // .Section6Container {
    //     background: #F4BD76;
    //     // padding: 10px 10px 130px 10px;
    // }
    

}