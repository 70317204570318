.F_15{
font-size: 15px;
}
.br_div{
    border-top: 3px solid;
    width: 24%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;

}
.Section2HEading {
    font-size: 35px;
    font-family: 'Poppins';
    font-weight: 400;
    margin-bottom: 0px;
}
.Section2_HEading3 {
    font-size: 35px;
    font-family: 'Poppins';
    font-weight: 600;
    line-height: 35px;
    margin-bottom: 0px;
    color: #E96F82;
}
.Section2Text{
    font-size: 20px;
    font-family: 'Poppins';
    font-weight: 300;
    margin-bottom: 0px;
    line-height: 1.3;
    text-align: justify;
    text-justify: inter-word;
}
.w-80{
    width: 75%;
    margin-top: 10px;
}

.w-81{
    width: 75%;
    margin-top: 20px;
}


.sideImgText {
    font-size: 16px;
    position: absolute;
    top: 24px;
    color: white;
    left: 10%;
    font-family: 'Poppins';
    font-weight: 500;
    line-height: 1.2;
}
.arrowIcon{
    z-index: 11;
    position: absolute;
  
    right: 38px;
    bottom: 25px;
    width: 51px;
}
.arrowIcon:hover{
    cursor: pointer;
    opacity: 0.8;
}
.Section2LeftImg {
    position: absolute;
    left: -55%;
    top: 46%;
    width: 59%;
}
.Section2RightImg {
    width: 72%;
    position: absolute;
    right: -54%;
    top: 10%;
}
@media (max-width: 767px) {
    .Section2HEading{
        font-size: 22px;
        text-align: center;
    }
    .Section2_HEading3{
        font-size: 38px;
        text-align: center;
    }
    .Section2Text{
text-align: center;
    }
}
