// .react-multiple-carousel__arrow{
//     display: none;
// }
.react-multiple-carousel__arrow::before {
    font-size: 14px;
    color: #fff;
    display: block;
    font-family: revicons;
    text-align: center;
    z-index: 2;
    position: relative;
}
.react-multiple-carousel__arrow {
    min-width: 30px;
    min-height: 30px;
    opacity: 0;
}
.react-multiple-carousel__arrow--left {
    left:0px}
    .react-multiple-carousel__arrow--right {
        right: 0px;
    }
    .react-multiple-carousel__arrow:focus{
        outline: none;
    }