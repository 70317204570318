.FooterContainer{
    margin-top: 50px;
    
    .bdtop1{
        background: #ED8B9A;
        height: 20px;
    }
    .bdtop2{
        background: #B0D3B8;
        height: 20px;

    }
    .bdtop3{
        background: #F4C07D;
        height: 20px;

    }
    .Footer{
        background: #393939;
        padding: 40px;
        color: white;
        position: relative;
        z-index: 22;

    }
    .FooterHeading{
        color: #9DC9A7;
        font-size: 30px;
    }
    .Text{
        color: white;
        font-size: 14px;
        line-height: 1.2;
        margin-bottom: 9px;
        margin-top: 9px !important;
    }
    .footer-link{
        color: #fff;
    }
    .footer-link:hover{
        cursor: pointer;
        opacity: 0.9;
    }
    .FooterIcon{
        height: 15px;
        margin: 6px;


    }
    .FooterPin{
        height: 24px;
        position: relative;
        top: -13px;
        margin: 6px;
    }
    .footerclock{
        height: 22px;
        position: relative;
        top: -9px;
        margin: 6px;
    }
    .footerlabel{
        line-height: 1;
        font-family: 'Poppins';
        font-weight: 400;
    }
    .lastimg{
        margin: 20px;
    }
    .lastimg1{
        margin: 40px 10px 20px 10px;
        height: 40px;
        width: 40px;
    
        }
}