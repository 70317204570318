.leftBorder {
    border: 1px solid;
    /* height: 6px; */
    width: 14%;
    border-radius: 16px;
    margin-top: 20px;
    position: absolute;
    left: 23%;
    top: 23px;
}
.rightBorder {
    border: 1px solid;
    /* height: 6px; */
    width: 14%;
    border-radius: 16px;
    position: absolute;
    margin-top: 20px;
    right: 23%;
    top: 23px;
}
.Section3HEading {
    font-size: 35px;
    font-family: 'Poppins';
    font-weight: 400;
    margin-bottom: 0px;
    margin-top: 20px;
}
.Section3_HEading3 {
    font-size: 35px;
    font-family: 'Poppins';
    font-weight: 600;
    line-height: 44px;
    margin-bottom: 0px;
    color: #F2B15D;
}
.OurWorkSection3_HEading3 {
    font-size: 35px;
    font-family: 'Poppins';
    font-weight: 600;
    line-height: 44px;
    color: #9DC9A7;
}
.OurWorkBtn{
    width: 187px;
    height: 54px;
    border: none;
    color: #9DC9A7;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Poppins';
    border-radius: 44px;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: 0px 0px 3px 2px #f7f7f7;
}
.OurWorkBtn:hover{
    background-color: black;
    color: white;
}

.OurWorkBtnlgs {
    width: calc(16.6666666667% - 20px);
    height: 41px;
    border: none;
    color: #9DC9A7;
    font-size: 12px;
    font-weight: 500;
    font-family: 'Poppins';
    border-radius: 44px;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: 0px 0px 3px 2px #f7f7f7;
}
.OurWorkBtnlgs:hover{
    background-color: black;
    color: white;
}
.publisherCardBtn{
    background: #F2B15D;
    height: 43px;
    width: 100%;
    font-size: 13px;
    font-family: 'Poppins';
    font-weight: 500;
    border: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    color: white;
    margin-top: 15px;
    position: absolute;
    bottom: 0px;
    width: 100% ;
}
.publisherCardBtn:focus{
    outline: none;
}
.publisherCardBtn:hover{
    background: white;
    border: 2px solid #F2B15D;
    color: #F2B15D;
}
.publishing-Card{
    box-shadow: 0px 0px 6px 3px #efefef;
    border-radius: 15px;
    height: 350px;
    background: white;
    z-index: 4;
    position: relative;
    margin-bottom: 20px;

}

.TextCardBtn:hover{
    color: #F2B15D;
}


.caraosolHeading{
    color: #393939;
    font-size: 21px;
    margin-top: 10px;
    font-family: 'Poppins';
    font-weight: 700;
}
.carosolText{
font-size: 13px;
font-family: 'Poppins';
font-weight: 500;
margin-top: 5px;
margin-bottom: 5px;
}
.Section3RightImg {
    width: 37%;
    position: absolute;
    right: 85%;
    top: -27%;
    opacity: 0.4;
    z-index: -1;
}
.Section3LeftImg {
    position: absolute;
    left: 91%;
    top: -35%;
    width: 35%;
}
@media (max-width: 767px) {
    .Section3HEading{
        font-size: 22px;
    }
    .rightBorder {
     
        right: 8%;
        top: 14px;
    }
    .leftBorder{
        left: 8%;
        top: 14px;
    }
    .Section3_HEading3 {
        font-size: 38px;
    }   
    .OurWorkSection3_HEading3{
        font-size: 38px;

    } 
}
