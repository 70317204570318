.ahmedGraphContainer{
    overflow: hidden;
}
.Logo-Img{
    width: 122px;
    height: 83px;
    margin-left: 15%;

}
.CardTextImg{
    margin: 8px;
    height: 18px;
}
.card{
    padding: 25px;
    border: none;
    border-top: 1px solid #e8e8e8;
    padding: 25px;
    box-shadow: 0px 2px 2px 0px #f5f5f5;
    

}
.navText{
    color: #707070;
    font-family: poppins;
    font-weight: 500;
    font-size: 16px;
    margin: 0px;
}
.navText:hover{
    cursor: pointer;
    opacity: 0.8;
}

.Vertical_center{
    margin-top: auto;
    margin-bottom: auto;
}
.f_14{
    font-size: 14px;
}
.headerActive{
    color: #9DC9A7;
    margin-bottom: 0px;
    text-decoration: none;

}
.headerActive:hover{
    cursor: pointer;
    opacity: 0.7;

}
.headernotActive{
    color: #707070;
    margin-bottom: 0px;

}
.headernotActive:hover{
    cursor: pointer;
    color: #9DC9A7;
}

    
.header-contain{
    width: 10.5%;
    text-align: center;
    margin: auto;

}
.header-contain-btn{
    width: 20%;
    text-align: center;
}
.contactBtn{
    width: 131px;
    height: 41px;
    background: #9DC9A7;
    border: none;
    border-radius: 100px;
    font-size: 14px;
    font-family: 'Poppins';
    font-weight: 600;
    color: white;
}
.contactBtn:focus{
    outline: none;
}
.contactBtn:hover{
    color: #9DC9A7;
    background: white;
    border: 3px solid #9DC9A7;
}
.bgleft{
    position: absolute;
    left: -27%;
    top: 67px;
    height: 488px;
    z-index: -1;
}
.bgright{
    position: absolute;
    right: -23%;
    height: 449px;
    z-index: -1;
    top: 34%;
}
.Section1{
    margin-top: 50px;
}
.Section11{
    margin-top: 120px;
}

    .HEading {
        font-size: 35px;
        font-family: 'Poppins';
        font-weight: 600;
        line-height: 38px;
        margin-bottom: 0px;
    }

.HEading2{
    font-size: 27px;
    font-family: 'Poppins';
    font-weight: 400;
    // margin-left: 20%;
    line-height: 44px;
    margin-bottom: 0px;
    font-weight: 300;


}
.HEading3{
    font-size: 35px;
    font-family: 'Poppins';
    font-weight: 600;
    // margin-left: 20%;
    line-height: 38px;
    margin-bottom: 0px;
    font-weight: 600;
    color: #9DC9A7;
}
.w-25{
    width: 25%!important;
    text-align: center !important;
    margin-top: auto;
    margin-bottom: auto;
}
.w-14{
    width: 14.28%!important;
    text-align: center !important;
    margin-top: auto;
    margin-bottom: auto;
}
.w-50{
    width: 50%!important;
    text-align: center !important;
    margin-top: auto;
    margin-bottom: auto;
}
.w-20{
    width: 20%!important;
    text-align: center !important;
    margin-top: auto;
    margin-bottom: auto;
}
.w-40{
    width: 40%!important;
    text-align: center !important;
    margin-top: auto;
    margin-bottom: auto;
}
.StandBook{
    width: 104%;
    margin-top: -12px;
    margin-left: -8px;

}
.im:hover{
    cursor: pointer;
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
.ImgContainer{
    background: white;
    width: 86px;
    margin-left: auto;
    margin-right: auto;
    height: 84px;
    box-shadow: 0px 0px 10px 0px #e0e0e0;
    border-radius: 10px;
    padding-top: calc(7% - 23px);
    margin-top: 25px;
}
.ImgContainer:hover{
    background: #F4C07D;
    cursor: pointer;
}

.section1_closebook {
  
    background: white;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    height: 100px;
    box-shadow: 0px 0px 10px 0px #e0e0e0;
    border-radius: 10px;
    margin-top: 25px;
    cursor: pointer;
    padding-top: 8px;



   


}
.section1_closebook:hover > .section1_closebookBg{
    background-image: url('../../Images/Section1/closebookwhite.png');
    
}
.section1_closebook:hover  {
    background-color: #F4C07D;
    color: white;
}

.section1_closebookBg {
    background-image: url('../../Images/Section1/closebook.svg');
    background-position: center;
    height: 85px;;
   width: 78px;
     background-repeat: no-repeat;
    visibility: visible;
    background-position: center;
    margin-left: auto;
    margin-right: auto;

}

.section1_Inpage {
  
    background: white;
    width: 100px;
    margin-left: auto;
    height: 100px;
    box-shadow: 0px 0px 10px 0px #e0e0e0;
    border-radius: 10px;
    margin-top: 25px;
    margin-right: 10px;
    padding-top: 8px;



   


}
.section1_Inpage:hover > .section1_Inpagebg{
    background-image: url('../../Images/Section1/inpageurdu.png');
    
}
.section1_Inpage:hover  {
    background-color: #393939;
    color: white;
    cursor: pointer;
}

.section1_Inpagebg {
    background-image: url('../../Images/Section1/InPage.svg');
    background-position: center;
    height: 85px;;
   width: 78px;
     background-repeat: no-repeat;
    visibility: visible;
    background-position: center;
    margin-left: auto;
    margin-right: auto;

}
.section1_Quran {
  
    background: white;
    width: 100px;
    margin-right: auto;
    height: 100px;
    box-shadow: 0px 0px 10px 0px #e0e0e0;
    border-radius: 10px;
    margin-top: 25px;
    margin-left: 10px;
    padding-top: 8px;



   


}
.section1_Quran:hover > .section1_Quranbg{
    background-image: url('../../Images/Section1/QuranWhite.png');
    
}
.section1_Quran:hover  {
    background-color: #F4C07D;
    color: white;
    cursor: pointer;
}

.section1_Quranbg {
    background-image: url('../../Images/Section1/QuranKareem.svg');
    background-position: center;
    height: 85px;;
   width: 78px;
     background-repeat: no-repeat;
    visibility: visible;
    background-position: center;
    margin-left: auto;
    margin-right: auto;

}


.section1_UI {
  
    width: 100px;
    margin-left: auto;
    margin-right: 10px;
    height: 100px;
    box-shadow: 0px 0px 10px 0px #e0e0e0;
    border-radius: 10px;
    margin-top: 25px;
    text-align: center;
padding-top: 8px;



   


}
.section1_UI:hover > .section1_UIbg{
    background-image: url('../../Images/Section1/ereaderwhite.png');
    
}
.section1_UI:hover  {
    background-color: #9DC9A7;
    color: white;
    cursor: pointer;
}

.section1_UIbg {
    background-image: url('../../Images/Section1/ereader.svg');
    background-position: center;
    height: 85px;;
   width: 78px;
     background-repeat: no-repeat;
    visibility: visible;
    background-position: center;
    margin-left: auto;
    margin-right: auto;

}
.section1_android {
  
    width: 100px;
    margin-left: 10px;
    margin-right: 10px;
    height: 100px;
    box-shadow: 0px 0px 10px 0px #e0e0e0;
    border-radius: 10px;
    margin-top: 25px;
    text-align: center;
padding-top: 8px;



   


}
.section1_android:hover > .section1_androidbg{
    background-image: url('../../Images/Section1/androidwhite.png');
    
}
.section1_android:hover  {
    background-color: #9DC9A7;
    color: white;
    cursor: pointer;
}

.section1_androidbg {
    background-image: url('../../Images/Section1/android.svg');
    background-position: center;
    height: 85px;;
   width: 78px;
     background-repeat: no-repeat;
    visibility: visible;
    background-position: center;
    margin-left: auto;
    margin-right: auto;

}

.section1_UII {
  
    width: 100px;
    margin-left: 10px;
    margin-right: auto;
    height: 100px;
    box-shadow: 0px 0px 10px 0px #e0e0e0;
    border-radius: 10px;
    margin-top: 25px;
    text-align: center;
padding-top: 8px;



   


}
.section1_UII:hover > .section1_UIIbg{
    background-image: url('../../Images/Section1/uiwhitee.png');
    
}
.section1_UII:hover  {
    background-color: #ED8B9B;
    color: white;
    cursor: pointer;
}

.section1_UIIbg {
    background-image: url('../../Images/Section1/ui.svg');
    background-position: center;
    height: 85px;;
   width: 78px;
     background-repeat: no-repeat;
    visibility: visible;
    background-position: center;
    margin-left: auto;
    margin-right: auto;

}




.ImgContainer1{
    background: white;
    width: 86px;
    margin-left: auto;
    height: 84px;
    box-shadow: 0px 0px 10px 0px #e0e0e0;
    border-radius: 10px;
    padding-top: calc(8% - 24px);
    margin-top: 25px;
    margin-right: 10px;
}
.ImgContainer1:hover{
    background: #F4C07D;
    cursor: pointer;
}
.ImgContainer2{
    background: white;
    width: 86px;
    margin-left: 10px;
    margin-right: auto;
    height: 84px;
    box-shadow: 0px 0px 10px 0px #e0e0e0;
    border-radius: 10px;
    padding-top: calc(7% - 23px);
    margin-top: 25px;
}
.ImgContainer2:hover{
    background: #F4C07D;
    cursor: pointer;
}
.ImgContainer5{
    background: white;
    width: 86px;
    margin-left: 10px;
    margin-right: auto;
    height: 84px;
    box-shadow: 0px 0px 10px 0px #e0e0e0;
    border-radius: 10px;
    padding-top: calc(7% - 23px);
    margin-top: 25px;
}
.ImgContainer5:hover{
    background: #F4C07D;
    cursor: pointer;
}
.ImgContainer3{
    width: 86px;
    margin-left: auto;
    margin-right: 10px;
    height: 84px;
    box-shadow: 0px 0px 10px 0px #e0e0e0;
    border-radius: 10px;
    padding-top: calc(6% - 23px);
    margin-top: 25px;
    text-align: center;
    padding-left: 5px;
}
.ImgContainer3:hover{
    background: #F4C07D;
    cursor: pointer;
}
.ImgContainer4{
    background: white;
    width: 86px;
    margin-left: 10px;
    margin-right: 10px;
    height: 84px;
    box-shadow: 0px 0px 10px 0px #e0e0e0;
    border-radius: 10px;
    padding-top: calc(7% - 23px);
    margin-top: 25px;
}
.ImgContainer4:hover{
    background: #F4C07D;
    cursor: pointer;
}
.closeBookIcon{
    height: 46px;
    width: 55px;
}
.uiIcon{
    height: 66px;
    width: 48px;
}
.androidIcon{
    height: 53px;
    width: 60px;
}
.ereaderIcon{
    height: 51px;
    width: 60px;
}
.Header{
    position: fixed;
    top: 0px;
    
    background: white;
    z-index: 999999;
}
.Header1 {
    position: fixed;
    top: 0px;
    background: black;
    color: white;
    z-index: 2;

}

@media (max-width: 767px) {
.navbaricon{
    position: absolute;
    top: 6px;
    right: 10px;
    height: 47px;
}
.Logo-Img {
    width: 88px;
    height: 60px;
}
    .header-contain {
     
        display: none;
    }
    .contactBtn{
        display: none;
    }
    .section1_UI {
        width: 85px;
        height: 85px;
        padding-top: 2px;    }
    .section1_android {
        width: 85px;
        height: 85px;
        padding-top: 2px;
    }
    .section1_UII{
        width: 85px;
        height: 85px;
        padding-top: 2px;
    }
    .section1_Inpage{

        width: 85px;
        height: 85px;
        padding-top: 2px;
    }
    .section1_Quran{
        width: 85px;
        height: 85px;
        padding-top: 2px;
    }
    .section1_closebook{
        width: 85px;
        height: 85px;
        padding-top: 2px;
    }
    .Section11 {
        margin-top: 110px;
    }
    .colpaseDiv{
        z-index: 2;
        background: #9DC9A7;
        text-align: left;
        padding: 46px;
        color: white;
    margin-top: 0px;
    }
    .navbar_li_text {
        color: #fff;
        font-size: 11px;
        border-bottom: 1px solid #e2e2e2;
        width: 100%;
        padding-bottom: 6px;
        padding-top: 6px;
        padding-left: 15px;
    }
    .navbar_li_text1 {
        color: #fff;
        font-size: 10px;
        width: 100%;
        padding-bottom: 0px;
        padding-top: 0px;
        padding-left: 15px;
    }
    .LOGOCENTER{
        text-align: center;
    }
    .Header {
        position: fixed;
        top: 0px;
        background: white;
        z-index: 3;
        box-shadow: 0px 1px 4px 2px #c5c5c5;
    }
    
    
    .Logo-Img{
        margin-left: 0px;
    }
    .HEading {
        font-size: 22px;
        text-align: center;
    }
    .HEading2 {
        font-size: 22px;
        text-align: center;
    }
    .HEading3 {
        font-size: 38px;
        text-align: center;
    }
    .bgleft{
        display: none;
    }
    .bgright{
        display: none;
    }
    .ImgContainer {
     
        padding-top: calc(12% - 23px);
    }   
    .ImgContainer2 {
     
        padding-top: calc(12% - 23px);
    }   
    .ImgContainer3 {
     
        padding-top: calc(12% - 23px);
    }  
    .ImgContainer4 {
     
        padding-top: calc(12% - 23px);
    }  
    .ImgContainer5 {
     
        padding-top: calc(12% - 23px);
    }  
    .im{
        margin-top: 20px;
    }
    .br_div{
        display: none;
    }
    .Section1 {
        margin-top: 30px;
    }
    .StandBook {
        width: 110%;
        margin-top: -22px;
        margin-left: -12px;
    }
    
}