.modalText{
    font-size: 15px;
}

.modalHeading{
    font-size: 40px;
    color: #ACD1B5;
}
.inputContainer{
    margin-top: 30px;
}
.modalInput{
    height: 40px;
    width: 100%;
    border: 2px solid #b9b9b9;
    padding-left: 20px;
    font-size: 14px;
    font-family: 'Poppins';
    font-weight: 600;
    opacity: 0.8;
    margin-top: 10px;
    margin-bottom: 10px;
}
.modalInput:focus{
    outline: none;
}
.modalInput:hover{
    border: 2px solid #ACD1B5;
}
.modalInput1{
    height: 100px;
    width: 100%;
    border: 2px solid #b9b9b9;
    padding-left: 20px;
    font-size: 14px;
    font-family: 'Poppins';
    font-weight: 600;
    opacity: 0.8;
    margin-top: 10px;
    margin-bottom: 10px;
}
.modalInput1:focus{
    outline: none;
}
.modalInput1:hover{
    border: 2px solid #ACD1B5;
}
.closebtn:hover{
    cursor: pointer;
    opacity: 0.8;
}
.ModalLogo{
    margin-top: 35px;
    height: 56px;
    margin-bottom: 20px;
}
.modalText1{
    font-size: 11px;
    margin: 10px 15px 10px 0px;


}
.modalIcons{
    position: absolute;
    top: 12px;
    right: -3px;
}
.modalBtn{
    font-weight: 500;
    background: #ACD1B5;
    color: white;
    width: 76%;
    height: 48px;
    font-size: 15px;
    border: none;
    font-family: 'Poppins';
    border-radius: 27px;
    margin-top: 30px;
    margin-right: -20px;
}
@media (min-width: 992px){
.modal-lg, .modal-xl {
    max-width: 1000px;
}
}