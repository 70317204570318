.Section10Container {
    padding: 30px;
}
.Section10HEading3 {
    font-size: 50px;
    font-family: 'Poppins';
    font-weight: 600;
    line-height: 44px;
    margin-bottom: 0px;
    font-weight: 400;
    color: #9DC9A7;
}
.s10topimg{
    height: 388px;

}

.s88topimg{
    height: 327px;
}
.s10topimg1{
    height: 278px;

}
.s10topimg2{
    height: 378px;

}
.s10topimg3 {
    height: 317px;
    width: 92%;
}
.qpsh{
height: 144px;

}
.s10br6{
    border-radius: 6px;
}
.s10heading{
    font-size: 22px;
}
.s10text{
    font-size: 14px;
    line-height: 1.3;
    opacity: 0.8;
}
.s10btn{
    width: 160px;
    height: 50px;
    background: #9DC9A7;
    border: none;
    border-radius: 100px;
    font-size: 14px;
    font-family: 'Poppins';
    font-weight: 600;
    color: white;
}
.s10btn:hover{
    border: 1px solid #9DC9A7;
    color: #9DC9A7;
    background: white;
}
.Client-Card {
    height: 110px;
    width: 110px;
    border-radius: 6px;
    box-shadow: 0px 0px 5px 0px #d1d1d1;
    margin: 34px 25px;
    text-align: center;
    padding: 13px;
}
.vertical_center{
    margin-top: auto;
    margin-bottom: auto;
}
.s10ClientSmTxt{

    font-size: 14px;
    margin: 0px;
}
.s10ClientmdTxt{

    font-size: 35px;
    margin: 0px;
}
.s10ClientlgTxt{

    font-size: 35px;
    color: #ACD1B5;
    margin: 0px;
}
.s10bd{
    border-top: 6px solid;
    width: 80px;
    margin-right: 10px;
    border-radius: 27px;
}
.s10lastText{
    font-size: 43px;
}
.s10service{
    color: #ACD1B5;
}
.s10Btnn{
    background: #393939;
    color: white;
    height: 46px;
    width: 60%;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins';
    border: none;
    border-radius: 100px;
}
.s10Btnn:hover{
    background: white;
    color: #393939;
    border: 3px solid #393939;
}