.S4bgleft {
    position: absolute;
    left: -26%;
    top: 81px;
    height: 465px;
    z-index: 1;
}
.S4bgright{
    position: absolute;
    right: -17%;
    top: 183px;
    height: 359px;
    z-index: 1;
}
.S6bgleft {
    position: absolute;
    left: -28%;
    top: 78px;
    height: 473px;
    z-index: 1;
}
.S6bgright{
    position: absolute;
    right: -13%;
    top: 354px;
    height: 276px;
    z-index: 1;
}
.S8bgleft {
    position: absolute;
    left: -27%;
    top: 85px;
    height: 465px;
    z-index: 2;
}
.S8bgright{
    position: absolute;
    right: -15%;
    top: 345px;
    height: 276px;
    z-index: 1;
}
.S9bgleft {
    position: absolute;
    left: -31%;
    top: -7px;
    height: 465px;
    z-index: -1;
}
.S9bgright{
    position: absolute;
    right: -17%;
    top: 85px;
    height: 276px;
    z-index: -1;
}
a:hover {
    color: #0056b3;
    text-decoration: none;
}

.S10bgleft {
   
    position: absolute;
    left: -13%;
    /* top: -7px; */
    height: 300px;
    z-index: -1;
    top: 165%;

}
.S10bgright{
    position: absolute;
    right: -12%;
    top: -35px;
    height: 400px;
    z-index: -1;
}
.socialMediaStripe{
    position: fixed;
    right: 1px;
    z-index: 2;
    top: 36%;
}
.instaContainer{
    height: 35px;
    width: 35px;
    background: #7D7D7D;
    padding-top: 9px;
    text-align: center;
    border: 1px solid #7D7D7D;



}
.instaContainer:hover{
    cursor: pointer;
    opacity: 0.8;
}
.fbContainer{
    height: 35px;
    width: 35px;
    background: #979797;
    padding-top: 9px;
    text-align: center;
    border: 1px solid #979797;


}
.linkdenContainer{
    height: 35px;
    width: 35px;
    background: #AAAAAA;
    padding-top: 9px;
    text-align: center;
    border: 1px solid #AAAAAA;



}
.twiterContainer{
    height: 35px;
    width: 35px;
    background: #C1C1C1;
    padding-top: 9px;
    text-align: center;
    border: 1px solid #C1C1C1;

}
.smediaIcons{
    height: 14px;
}
.fbContainer:hover{
    cursor: pointer;
    opacity: 0.8;
}
.twiterContainer:hover{
    cursor: pointer;
    opacity: 0.8;
}
.linkdenContainer:hover{
    cursor: pointer;
    opacity: 0.8;
}

@media (max-width: 767px) {
    .instaContainer {
        height: 30px;
        width: 30px;
        padding-top: 6px;

    }
    .fbContainer {
         height: 30px;
         width: 30px;
         padding-top: 6px;

        }
        .linkdenContainer{
            height: 30px;
         width: 30px;
         padding-top: 6px;

        }
        .twiterContainer{
            height: 30px;
            width: 30px;
            padding-top: 6px;


        }
        .smediaIcons {
            height: 15px;
        }
        .S4bgleft {
           display: none;
        }
        .S4bgright{
            display: none;

        }
        .S6bgleft {
            display: none;

        }
        .S6bgright{
            display: none;

        }
        .S8bgleft {
            display: none;

        }
        .S8bgright{
            display: none;

        }
        .S9bgleft {
            display: none;

        }
        .S9bgright{
            display: none;

        }
        
        .S10bgleft {
           
            display: none;

        }
        .S10bgright{
            display: none;

        }

}